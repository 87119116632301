<template>
    <b-overlay :show="isLoadingPromos">
        <b-card>
            <div class="head-btn">
                <b-button
                    v-if="can('fivesclub_web_owners_show_the_fives_vacations_list_create_button')"
                    :to="{ name: 'create-fives-vacations'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                    <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
            </div>
            <b-table
                :items="listPromos"
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No hay registros"
                class="position-relative mb-0"
            >
                <template #cell(actions)="row">
                    <div>
                        <b-button
                            v-if="can('fivesclub_web_owners_show_the_fives_vacations_list_edit_button')"
                            size="sm"
                            :to="{ name: 'edit-fives-vacations', params: { id: row.item.id} }"
                            variant="primary"
                        >
                            <feather-icon icon="Edit2Icon" size="12" />
                        </b-button>
                    </div>
                </template>

                <template #cell(status)="row">
                    <div class="">
                        <b-badge pill :variant="row.item.status ? 'success' : 'danger'">
                            {{ row.item.status ? "Activo" : "Desactivado" }}
                        </b-badge>
                    </div>
                </template>
            </b-table>
        </b-card>
    </b-overlay>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive'
import { acl } from "@/modules/auth/mixins/acl"

export default {
    mixins: [acl],
    directives: { Ripple, },
    components: {

    },
    async created(){
        await this.init()
    },
    data(){
        return {
            isLoadingPromos: false,
            listPromos: [],
            tableColumns: [
                { key: 'namePromo', label: 'Nombre', class: 'text-center' },
                { key: 'codePromo', label: 'Código', class: 'text-center' },
                { key: 'resort', label: 'Hotel', class: 'text-center', formatter: value=>{
                    return this.hotels.find(hotel => hotel.id == value)?.name
                }},
                { key: 'status', label: 'Estado', class: 'text-center' },
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
        }
    },
    computed: {
        ...mapState('start', ["hotels"]),
        ...mapState("auth", ["user"]),
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ["getInitialContentForTheFivesVacations", "fetchOwnerPromos"]),
        ...mapMutations('start',['setHotels']),

        async init(){
            this.isLoadingPromos = true
            const { hotels } = await this.getInitialContentForTheFivesVacations()
            const ownerPromos = await this.fetchOwnerPromos({idPromo:null})
            this.listPromos = ownerPromos

            if( !this.hotels.length ) this.setHotels(hotels)

            this.isLoadingPromos = false
        },
    },
};
</script>

<style scoped>
.head-btn{
display: flex;
justify-content: flex-end;
margin-bottom: 1rem;
}

.actions-status{
display: flex;
align-items: center;
gap: 1rem;
}
</style>
